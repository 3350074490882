@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
.App {
	font-family: 'Lato';
	height: 100vh;
	width: 100vw;
	background-color: #202020;
	.logo {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		height: 100%;
		width: 100%;
		img {
			height: 120px;
		}
		.welcome {
			font-size: 20px;
			color: rgba(255, 255, 255, 0.7);
			margin-top: 30px;
			margin-bottom: 10px;
		}
		.connect {
			font-size: 18px;
			font-weight: bold;
			color: white;
			margin-top: 30px;
		}
		.status {
			font-size: 16px;
			color: rgba(255, 255, 255, 0.7);
			margin-top: 10px;
		}
		.slider {
			position: relative;
			width: 15vw;
			height: 10px;
			margin-top: 30px;
			overflow-x: hidden;
			border-radius: 10px;
		}

		.line {
			position: absolute;
			background: #434343;
			border-radius: 10px;
			width: 150%;
			height: 10px;
		}

		.subline {
			position: absolute;
			background: #1da0bc;
			height: 10px;
			border-radius: 10px;
		}
		.inc {
			animation: increase 2s infinite;
		}
		.dec {
			animation: decrease 2s 0.5s infinite;
		}

		@keyframes increase {
			from {
				left: -5%;
				width: 5%;
			}
			to {
				left: 130%;
				width: 100%;
			}
		}
		@keyframes decrease {
			from {
				left: -80%;
				width: 80%;
			}
			to {
				left: 110%;
				width: 10%;
			}
		}
	}
}
